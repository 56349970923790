import { isRouteErrorResponse, useRouteError } from '@remix-run/react'
import { captureRemixErrorBoundaryError, withScope } from '@sentry/remix'

import ErrorPage from './ErrorPage'

import ERRORS from '~/res/constants/errors'

const ErrorBoundary = () => {
  const error = useRouteError()

  if (!(process.env.NODE_ENV === 'production' && typeof window !== 'undefined'))
    // eslint-disable-next-line no-console
    console.error(error)

  /**
   * Handles access to routes not found
   */
  if (isRouteErrorResponse(error)) {
    if (error.status == 404) {
      return (
        <ErrorPage
          text={ERRORS.DOES_NOT_EXIST}
          label="Voltar para tela inicial"
          href="/"
          variant="primary"
        />
      )
    } else {
      if (typeof error.data === 'string') {
        error.data = { data: error.data }
      }

      withScope((scope) => {
        scope.setContext('Error Response in Remix Boundary', {
          status: error.status,
          statusText: error.statusText,
          data: error.data,
          internal: error.internal,
        })
        captureRemixErrorBoundaryError(error)
      })
    }

    /**
     * Handles possible loading errors
     */
    if (error.status == 500) {
      return (
        <ErrorPage
          text={ERRORS.CUSTOMER_LOADING_ERROR}
          label="Voltar para tela inicial"
          href="/"
          variant="neutral"
        />
      )
    }
  }
  /**
   * Handles any other possible exceptions
   */
  return <ErrorPage text={ERRORS.INTERNAL_ERROR} />
}
export default ErrorBoundary
